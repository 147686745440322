<template>
  <div class="sales-item">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.platform')"
        >
          <el-select style="width: 108px" v-model="platform" filterable>
            <el-option
              v-for="item in platformList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 128px" v-model="date" clearable>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="download-btn">
        <el-button
          :disabled="!date"
          size="small"
          class="excel-dl-btn"
          icon="el-icon-download"
          @click="downloadAll"
          >{{ $t("analysis.downloadAll") }}</el-button
        >
      </div>
    </div>
    <div class="data-content" v-if="date && brandId">
      <el-tabs v-model="brandId" type="card">
        <el-tab-pane
          v-for="item in brands"
          :label="item.name"
          :name="item.id.toString()"
          :key="item.id"
        ></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" row-key="id">
        <el-table-column
          prop="item"
          :label="$t('salesItem.item')"
        ></el-table-column>
        <el-table-column
          prop="styleAmount"
          :label="$t('salesItem.styleAmount')"
        ></el-table-column>
        <el-table-column
          prop="saleQty"
          :label="$t('salesItem.saleQty')"
        ></el-table-column>
        <el-table-column :label="$t('salesItem.minListPrice')"
          ><template slot-scope="scope">{{
            "￥" + scope.row.minListPrice
          }}</template></el-table-column
        >
        <el-table-column :label="$t('salesItem.maxListPrice')"
          ><template slot-scope="scope">{{
            "￥" + scope.row.maxListPrice
          }}</template></el-table-column
        >
        <el-table-column :label="$t('salesItem.middleListPrice')"
          ><template slot-scope="scope">{{
            "￥" + scope.row.middleListPrice
          }}</template></el-table-column
        >
        <el-table-column :label="$t('salesItem.minSalePrice')"
          ><template slot-scope="scope">{{
            "￥" + scope.row.minSalePrice
          }}</template></el-table-column
        >
        <el-table-column :label="$t('salesItem.maxSalePrice')"
          ><template slot-scope="scope">{{
            "￥" + scope.row.maxSalePrice
          }}</template></el-table-column
        >
        <el-table-column :label="$t('salesItem.middleSalePrice')"
          ><template slot-scope="scope">{{
            "￥" + scope.row.middleSalePrice
          }}</template></el-table-column
        >
        <el-table-column :label="$t('salesItem.discountRate')"
          ><template slot-scope="scope">{{
            scope.row.discountRate + "%"
          }}</template></el-table-column
        >
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :offset.sync="listQuery.offset"
        :limit.sync="listQuery.limit"
        layout="sizes, prev, pager, next, total "
        @pagination="fetchData"
      />
    </div>
  </div>
</template>

<script>
import { fetchRecords, fetchUserBrands, fetchItemSales } from "@/api/analysis";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";
import XLSX from "xlsx";
import Guide from "@/components/Guide";

export default {
  components: { AiBreadcrumb, Pagination, Guide },
  data() {
    return {
      platform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      date: null,
      brandId: null,
      options: [],
      brands: [], // {"id": 1, name: "Only"}
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
    };
  },
  created() {
    this.fetchOptions();
  },
  methods: {
    fetchOptions() {
      this.date = "";
      this.options = [];
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.platform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.options.push({
                value: e.date,
                label: e.linkDate,
              });
            });
          if (this.options.length > 0) {
            this.date = this.options[0].value;
          }
        }
      });
    },
    fetchBrands() {
      this.brandId = null;
      this.brands = [];
      this.tableData = [];
      this.date &&
        fetchUserBrands({ dates: this.date }).then((response) => {
          if (response.success) {
            this.brands = response.result.items || [];
            this.brandId =
              this.brands.length > 0 ? this.brands[0].id.toString() : null;
          }
        });
    },
    fetchData() {
      this.tableData = [];
      if (!this.brandId) {
        return;
      }
      let params = {
        date: this.date,
        brandId: this.brandId,
        ...this.listQuery,
      };
      fetchItemSales(params).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    downloadAll() {
      fetchItemSales({ date: this.date, limit: -1 }).then((response) => {
        if (response.success) {
          let results = {};
          response.result.items &&
            response.result.items.forEach((e) => {
              if (!results[e.brandId]) {
                results[e.brandId] = [];
              }
              results[e.brandId].push(e);
            });
          let lists = [];
          this.brands.forEach((b) => {
            results[b.id] = results[b.id] ? results[b.id] : [];
            let items = results[b.id].map((t) => {
              let o = {};
              o[this.$t("salesItem.item")] = t.item;
              o[this.$t("salesItem.styleAmount")] = t.styleAmount;
              o[this.$t("salesItem.saleQty")] = t.saleQty;
              o[this.$t("salesItem.minListPrice")] = "￥" + t.minListPrice;
              o[this.$t("salesItem.maxListPrice")] = "￥" + t.maxListPrice;
              o[this.$t("salesItem.middleListPrice")] =
                "￥" + t.middleListPrice;
              o[this.$t("salesItem.minSalePrice")] = "￥" + t.minSalePrice;
              o[this.$t("salesItem.maxSalePrice")] = "￥" + t.maxSalePrice;
              o[this.$t("salesItem.middleSalePrice")] =
                "￥" + t.middleSalePrice;
              o[this.$t("salesItem.discountRate")] = t.discountRate + "%";
              return o;
            });
            lists.push({ name: b.name, items: items });
          });
          let username =
            this.$store.getters.my && this.$store.getters.my.userName
              ? this.$store.getters.my.userName
              : "";
          let filename = this.date + "_sales_item_list_" + username + ".xlsx";
          let wb = XLSX.utils.book_new();
          lists.forEach((e) => {
            let ws = XLSX.utils.json_to_sheet(e.items);
            let name = e.name;
            // \ / ? * [ ]
            name = name.replaceAll("\\", "");
            name = name.replaceAll("/", "");
            name = name.replaceAll("?", "");
            name = name.replaceAll("*", "");
            name = name.replaceAll("[", "");
            name = name.replaceAll("]", "");
            XLSX.utils.book_append_sheet(wb, ws, name);
          });
          XLSX.writeFile(wb, filename);
        }
      });
    },
  },
  watch: {
    platform() {
      this.fetchOptions();
    },
    date() {
      this.fetchBrands();
    },
    brandId() {
      this.listQuery.offset = 0;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-item {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .download-btn {
      display: inline-block;
    }
  }

  .data-content {
    background-color: #fff;
    padding: 18px 10px 0 10px;
  }
}
</style>